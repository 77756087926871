body {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: top center !important;
  min-height: -webkit-fill-available !important;
  height: 100vh !important;
  background-color: rgb(243, 243, 243);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40px;
  float: left;
}

.Logo {
  margin-left: 0px !important;
}

.App-logo-mobile {
  height: 35px;
  position: relative;
}

.App-header {
  font-family: 'Montserrat';
  font-weight: 700;
  color: white;
  background-color: #56BFB7;
  height: 60px;
  padding: 10px;
  padding-left: 40px;
  opacity: 1;
}

.App-header-mobile {
  font-family: 'Montserrat';
  font-weight: 700;
  color: white;
  background-color: #56BFB7;
  height: 80px;
  padding: 28px;
  opacity: 1;
}

.App-intro {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
    text-align: left;
    font-family: 'Lato';
}

.Text {
  display:inline;
}

.PoweredHeader {
  font-family: 'Montserrat';
  color: #56BFB7;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px; 
}

.PoweredHeaderAbout {
  color: gray;
  font-size: 14px;
  text-align: center;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* unvisited link */
a:link {
    color: white;
}

/* visited link */
a:visited {
    color: white;
}

/* mouse over link */
a:hover {
    color: white;
}

/* selected link */
a:active {
    color: white;
}

.transition-enter {
  transform: scale(0);
}
.transition-enter-active {
  transform: scale(1);
  transition: transform 1000ms ease;
}
.transition-exit {
  transform: scale(1);
}
.transition-exit-active {
  transform: scale(0);
  transition: transform 1000ms ease;
}

.language{
  position:absolute;
  top:10px;
  right: 50px;
}

.buzz {
  position:absolute;
  top:10px;
  right: 10px;
}

.languagemobile{
  position:absolute;
  top:10px;
  right: 80px;
}

.buzzmobile {
  position:absolute;
  top:10px;
  right: 40px;
}

.ui.segment.inverted.footer {
  background-color: #56BFB7;
  color: white;
  opacity: 0.85;
}

.ui.menu {
  margin: 0 0 !important;
}

.ui.inverted.menu {
  background-color: #56BFB7 !important;
}

.twitter-tweet {
  margin: auto;
}

.AnyClassForContainer {
  position: fixed;
  right: -50px;
  bottom: 10px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.AnyClassForTransition {
  right: 20px;
}

/* unvisited link */
a:link {
  color: #56BFB7;
}

/* visited link */
a:visited {
  color: #56BFB7;
}

/* mouse over link */
a:hover {
  color: #56BFB7;
}

/* selected link */
a:active {
  color: #56BFB7;
}